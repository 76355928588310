.mb-10 {
	margin-block-end: 10px;
}

.block-fill {
	background: var(--sidebar-block-bg);
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	&.no-pd {
		padding: 0;
	}
}

.sidebar {
	position: sticky;
	height: 100vh;
	top: 0;
	inset-inline-start: 0;
	z-index: 10;
	width: 256px;
	background: var(--sidebar-bg);
	box-shadow: 20px 4px 47px rgba(0, 0, 0, 0.15);
	overflow: auto;
	&-container {
		padding: 20px 15px;
		&-time {
			padding: 0 15px 20px;
		}
	}
	&::-webkit-scrollbar {
		width: 0px;
		background: transparent; /* make scrollbar transparent */
	}
	&-between {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&-logo {
		width: 100%;
		height: auto;
		margin-block-end: 30px;
	}
	&-profile {
		margin-block-end: 30px;
		padding-block-end: 25px;
		&-img {
			display: flex;
			align-items: center;
			margin: -48px auto 20px;
			width: 80px;
			height: 80px;
			img {
				width: 100%;
				height: 100%;
			}
		}

		&-btn {
			margin-block-end: 10px;
			button {
				width: 100%;
			}
		}
		&-username {
			text-align: center;
			font-weight: 700;
			font-size: 16px;
			color: var(--classic-color);
		}
		&-amount {
			display: flex;
			justify-content: space-between;
			background: var(--input-background);
			box-shadow: inset 0px 0px 8px rgba(255, 255, 255, 0.1);
			border-radius: 42px;
			p {
				margin: 0;
				padding: 10px 15px;
				color: var(--classic-color);
			}
			&-add {
				background: var(--theme-color);
				border-radius: 100%;
				width: 45px;
				height: 45px;
				font-weight: 700;
				font-size: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #000000;
				transition: .2s;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					inset-inline-start: 0;
					box-shadow: inset 0px -6px 5px rgba(0, 0, 0, 0.25);
					border-radius: 50%;
				}
				span {
					position: relative;
					top: -1px;
				}
				&:hover {
					box-shadow: var(--button-filled-shadow-hover);
				}
			}
		}
	}
	&-block {
		.divider {
			width: calc(100% - 20px);
			margin: 0 auto;
			height: 1px;
			opacity: .1;
			background: var(--classic-color);
		}
	}
	&-link {
		display: flex;
		align-items: center;
		text-decoration: none;
		&.block-pd {
			padding: 10px 10px;
		}
		&.sidebar-link-promotions {
			margin-block-end: 15px;
		}
		&-icon {
			margin-inline-end: 15px;
			position: relative;
			width: 17px;
			height: 17px;
			img {
				height: 100%;
				width: 100%;
				object-fit: contain;
				transition: .2s;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				inset-inline-start: 0;
				width: 140%;
				height: 140%;
				inset-inline-start: -20%;
				top: -20%;
				transition: .2s;
				background: var(--theme-color);
				box-shadow: var(--sidebar-link-shadow);
				border-radius: 50%;
				opacity: 0;
			}
		}
		span {
			color: var(--sidebar-link-color);
			font-size: 14px;
			transition: .2s;
		}
		&.active, &:hover {
			.sidebar-link-icon {
				&:before {
					opacity: 1;
				}
				img {
					filter: brightness(0%);
				}
			}
			span {
				color: var(--theme-color);
			}
		}
	}
}
