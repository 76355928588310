.dobDatePicker {
  width: 100%;
  background: var(--input-background);
  border-radius: 5px;
  overflow: hidden;
  &.sign-up {
    &.error {
      fieldset {
        border-color: rgb(214, 76, 76);
      }
    }

    input {
      &::placeholder {
        color: #b7b7b7;
        opacity: 1;
      }
    }
    fieldset {
      border-color: var(--input-border-color);
      transition: 0.2s;
      top: 0;
      legend {
        display: none;
      }
    }
    .MuiInputBase-root {
      &:hover {
        fieldset {
          border-color: #fff;
        }
      }
    }
    .Mui-focused fieldset {
      border-color: #fff;
    }
  }
  .MuiInputAdornment-root {
    order: -1;
    margin-left: 0;
  }
  .MuiIconButton-root {
    padding: 2px;
  }
  input {
    font-size: 14px;
    font-family: "Inter", sans-serif;
    color: #fff;
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    @media (hover: hover) {
      padding-inline-start: 17px;
    }
    @media (hover: none) {
      padding-inline-start: 0;
    }
    &::placeholder {
      color: #ababac;
    }
  }
  .MuiIconButton-root {
    color: #ababac;
  }
  fieldset {
    border-color: transparent;
    border-width: 1px !important;
  }
  .MuiInputBase-root {
    @media (hover: hover) {
      padding: 0 11px 0 9px;
    }
    @media (hover: none) {
      padding: 0 11px;
    }
    &.Mui-error {
      fieldset {
        border-color: rgb(214, 76, 76) !important;
      }
    }
    &:hover {
      fieldset {
        border-color: transparent;
      }
    }
  }
  .Mui-focused fieldset {
    border-color: transparent !important;
  }
  .MuiSvgIcon-root {
    display: block !important;
  }
}

.MuiPickersYear-yearButton,
.MuiPickersDay-root,
.MuiPickersMonth-monthButton {
  &.Mui-selected {
    background-color: var(--theme-color) !important;
  }
}
