.ant-result
  .ant-result-icon
  .ant-result-title
  .ant-result-subtitle
  .ant-result-extra {
  background-color: #ffffff !important;
}

.ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.08);
}
.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: var(--theme-color);
}
.ant-menu-inline .ant-menu-item:after,
.ant-menu-inline .ant-menu-item-selected:after {
  display: none;
}

.ant-menu-light .ant-menu-item:hover {
  color: #a3a3a3;
}
.ant-menu-item:focus-visible {
  box-shadow: none !important;
}
.ant-menu-inline > .ant-menu-item {
  height: 50px;
  line-height: 30px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: inherit;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
  border-inline-end: 0;
}

.ant-menu-title-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ant-layout-sider-children {
  background-color: var(--sidebar-bg);
  box-shadow: var(--sidebar-shadow);
}
.ant-menu {
  background-color: unset !important;
}
