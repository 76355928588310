.spin-loader {
  position: absolute;
  top: 50%;
  inset-inline-start: 50%;
  margin: -50px 0px 0px -50px;
  backdrop-filter: blur(6px);
}

.spin-loader-dark {
  background: var(--sidebar-block-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
