.sidebarM {
	transform: translate3d(100%, 0, 0);
	max-inline-size: 350px;
	background: var(--sidebar-bg);
	width: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	overflow-y: auto;
	height: 100%;
	z-index: 11;
	inset-inline-end: 0;
	transition: transform 0.2s ease-in-out;
	backface-visibility: hidden;
	padding: 30px 20px;
	&:not(.sidebarM-main) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&.active {
		transform: translate3d(0, 0, 0);
	}
	&-header {
		display: flex;
		justify-content: space-between;
		margin-block-end: 40px;
		&.align-center {
			align-items: center;
		}
		&-switch {
			display: flex;
			align-items: center;
			.navbar-theme {
				margin-inline-end: 20px;
			}
		}
	}
	&-close {
		display: flex;
		cursor: pointer;
	}
	&-user {
		display: flex;
		align-items: center;
		&-img {
			width: 40px;
			height: 40px;
			margin-inline-end: 10px;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	&-links {
		.sidebarM-link:not(:last-child) {
			margin-block-end: 20px;
		}
	}
	&-link {
		display: flex;
		align-items: center;
		color: var(--sidebar-link-color);
		font-size: 16px;
		font-weight: 500;
		text-decoration: none;
		&:not(.sidebarM-logout) {
			justify-content: space-between;
		}
		&-row {
			display: flex;
			align-items: center;
			img {
				margin-inline-end: 11px;
				opacity: 0.7;
			}
		}
	}
	&-logout {
		img {
			margin-inline-end: 11px;
			opacity: .7;
		}
	}
	&-divider {
		background: rgba(183, 183, 183, 0.5);
		width: 100%;
		height: 1px;
		margin: 30px 0 100px;
	}
	&-search {
		margin-block-end: 30px;
		display: grid;
		grid-template-columns: 1fr auto auto;
		grid-gap: 10px;
		align-items: center;
		&-games {
			max-height: 500px;
			height: 100%;
			overflow-y: auto;
			.gameCard {
				display: flex;
				align-items: center;
				width: 100%;
				&:not(:last-child) {
					margin-block-end: 5px;
					padding-block-end: 5px;
					border-bottom: 1px solid rgba(183, 183, 183, 0.2);
				}
				&-img {
					width: 50px;
					height: 50px;
					margin-inline-end: 15px;
				}
				&-name {
					margin: 0;
					text-align: left;
				}
				.glow {
					display: none;
				}
			}
		}
		&-divider {
			margin: 30px 0;
			width: 100%;
			height: 1px;
			opacity: .5;
			background: var(--classic-color);
		}
		.navbar-search {
			max-width: initial;
		}
	}
	&-back {
		display: inline-flex;
		align-items: center;
		font-size: 12px;
		color: var(--sidebar-link-color);
		margin-block-end: 20px;
		img {
			margin-inline-end: 10px;
		}
	}
	&-lang {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			color: var(--sidebar-link-color);
			font-size: 16px;
			margin: 0;
		}
	}
	.light-theme-icon {
		@media screen and (max-width: 1320px) {
			filter: brightness(0);
			opacity: .5;
		}
	}
	&-profile {
		&-name {
			color: #fff;
			margin-block-end: 7px;
		}
		&-balance {
			color: var(--theme-color);
		}
	}
	&-products {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 12px;
		margin-bottom: 32px;
		&-item {
			padding: 16px;
			background: rgba(255, 255, 255, .04);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 12px 24px;
			border-radius: 12px;
			img {
				margin-bottom: 10px;
				width: 100%;
				height: auto;
			}
			.landHeader__products-line {
				display: block;
				opacity: 1;
			}
		}
	}
}

.sidebarM-main {
	transform: translate3d(-100%, 0, 0);
	inset-inline-end: initial;
	inset-inline-start: 0;
	display: grid;
	&-divider {
		background: rgba(183, 183, 183, 0.5);
		width: 100%;
		height: 1px;
		margin: 23px 0;
	}
}

@media screen and (max-width: 700px) {
	.sidebarM {
		max-width: 100%;
	}
	.sidebarM-products {
		max-width: 350px;
	}
}
