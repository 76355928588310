.promo {
	&__banner {
		padding: 35px 50px;
		border-radius: 8px;
		margin-bottom: 30px;
		background-repeat: no-repeat;
		background-size: cover;
		&-content {
			border-radius: 16px;
			background: rgba(0, 0, 0, 0.64);
			max-width: 626px;
			width: 100%;
			padding: 24px;
		}
		&-title {
			color: #FFF;
			text-shadow: 0px 0px 29px #FFF;
			font-size: 50px;
			font-weight: 700;
			letter-spacing: 0.132px;	
		}
		&-text {
			color: #FFF;
			font-size: 15px;
			letter-spacing: 0.042px;
		}
		&-line {
			margin: 15px 0;
			width: 100%;
			height: 1px;
			background: rgba(255, 255, 255, .7);
			box-shadow: 0px 0px 29px rgba(255, 255, 255, 0.99);
		}
	}
	.casino-nav {
		grid-template-columns: 1fr;
		border-left: 8px solid #3CC;
		padding: 5px 10px;
	}
	.casino-category {
		svg {
			margin-right: 8px;
		}
		&.active, &:hover {
			svg {
				path {
					fill: #3CC;
				}
			}
		}
	}
	&__content {
		border-radius: 20px;
		background: #1D282D;
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
		padding: 24px;
		margin-top: 30px;
		&-link {
			width: 100%;
			border-radius: 10px;
			overflow: hidden;
			display: flex;
			transition: .15s;
			border: 1px solid transparent;
			&:hover {
				border-color: #54B5D5;
				box-shadow: 0px 0px 40px 0px rgba(84, 181, 213, 0.72);
			}
			img {
				width: 100%;
				height: auto;
			}
			&:not(:first-child) {
				margin-top: 24px;
			}
		}
	}
}

.singlePromo {
	&__content {
		// max-width: 1000px;
		// margin: 0 auto;
		width: 100%;
	}
	&__block {
		border-radius: 16px;
		background: #1D282D;
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
		padding: 48px;
		color: #fff;
	}
	&__back {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 12px;
		margin-top: 20px;
		align-items: center;
		color: #FFF;
		text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.32);
		font-size: 24px;
		font-weight: 700;
		letter-spacing: 0.063px;
		margin-bottom: 20px;
		text-decoration: none;
		position: relative;
		left: -28px;
		&:hover {
			color: var(--theme-color);
		}
		svg {
			width: 76px;
			height: auto;
		}
	}
	&__img {
		width: 100%;
		height: auto;
		margin-bottom: 48px;
		border-radius: 12px;
	}
	&__list {
		margin: 24px 0;
		border-radius: 8px;
		padding: 10px;
		background: rgba(255, 255, 255, 0.08);
	}
	&__title {
		color: #FFF;
		font-size: 24px;
		font-weight: 700;
		letter-spacing: 0.063px;
		margin-bottom: 32px;
	}
	&__heading {
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 0.063px;
		margin-bottom: 0;
		&.mb {
			margin-bottom: 24px;
		}
	}
	&__text {
		font-size: 16px;
		margin-bottom: 0;
	}
}

@media screen and (max-width: 1320px) {
	.promo__banner-px {
		padding-left: 0;
		padding-right: 0;
	}
	.promo__banner {
		border-radius: 0;
	}
	.singlePromo__block {
		padding: 30px;
	}
}

@media screen and (max-width: 991px) {
	.singlePromo__back {
		margin: 15px 0;
		font-size: 20px;
		grid-gap: 0;
		left: -24px;
		svg {
			width: 60px;
		}
	}
	.singlePromo__img {
		margin-bottom: 30px;
	}
	.singlePromo__title {
		font-size: 20px;
		margin-bottom: 24px;
	}
	.singlePromo__heading {
		font-size: 18px;
	}
}

@media screen and (max-width: 830px) {
	.promo__banner-title {
		font-size: 35px;
	}
	.promo__banner {
		background-position: center;
		padding: 30px;
	}
	.promo__banner-content {
		max-width: initial;
	}
}

@media screen and (max-width: 700px) {
	.promo__banner {
		background-position: center;
		padding: 15px;
	}
	.promo .casino-category svg {
		width: 32px;
		height: 32px;
	}
	.promo__content {
		padding: 12px;
	}
	.promo__content-link:not(:first-child) {
		margin-top: 12px;
	}
	.singlePromo__block {
		padding: 25px 15px;
	}
	.singlePromo__img {
		height: 200px;
		object-fit: cover;
		object-position: center;
	}
	.singlePromo__img {
		margin-bottom: 24px;
	}
	.singlePromo__title {
		font-size: 16px;
	}
	.singlePromo__heading,
	.singlePromo__text,
	.singlePromo__list {
		font-size: 14px;
	}
}

@media screen and (max-width: 500px) {
	.promo__content {
		padding: 0;
		background: 0;
		box-shadow: none;
	}
	.promo__content-link {
		border-radius: 4px;
		img {
			height: 100px;
			object-fit: cover;
			object-position: center;
		}
	}
}

@media screen and (max-width: 525px) {
	.singlePromo__img {
		height: 111px;
		border-radius: 7px;
	}
	.singlePromo__back {
		font-size: 16px;
	}
}

@media screen and (max-width: 430px) {
	.promo__banner-title {
		font-size: 22px;
	}
	.promo__banner-text {
		font-size: 14px;
	}
	.promo__banner-content {
		padding: 15px;
		border-radius: 0;
	}
	.promo__banner {
		padding: 0;
	}
}


