.theme-modal {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  overflow: hidden;
  background: var(--sidebar-block-bg);
	
  &-body {
		position: relative;
		padding: 30px;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  &-heading {
    color: var(--classic-color);
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    &.pd-r {
      padding-inline-end: 15px;
    }
  }
  &-close {
    display: flex;
    align-items: center;
    padding: 6px;
		flex-shrink: 0;
		flex-grow: 0;
    &.light-theme {
      filter: brightness(0);
    }
		&.absolute {
			position: absolute;
			top: 10px;
			inset-inline-end: 10px;
		}
  }
	&-iframe {
		margin-block-start: 20px;
	}
	&:not(.default-close) {
		.ant-modal-close {
			display: none;
		}
	}	
}

.addAccountModal {
	&-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px 15px;
		@media screen and (max-width: 500px) {
			grid-template-columns: 1fr;
		}
	}
	&-btn {
		margin-block-start: 30px;
	}
	.ant-form-item {
		margin-block-end: 0 !important;
	}
}


@media screen and (max-width: 500px) {
  .theme-modal-body {
    padding: 20px;
  }
  .theme-modal-heading {
    font-size: 20px;
  }
  .theme-modal-close {
    width: 25px;
    height: 25px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

//--reset
.ant-modal-content {
  background: transparent;
}
.ant-modal-body {
  padding: 0;
}
.ant-modal {
  padding: 0;
}

@media screen and (max-width: 800px) {
}
