.games {
	&-grid {
		margin-block-start: 50px;
	}
}
.terms-condition-block{
	display: flex;
	justify-content: space-between;
}

.pdf-button {
	background: var(--theme-color);
    color: var(--fill-btn-color);
    border: none; /* Remove border */
    padding: 8px; /* Add padding */
    border-radius: 5px; /* Add border radius */
  }
  .pdf-button img {
    fill: var(--fill-btn-color);; /* Set SVG fill color to white */
    width: 30px; /* Set width of SVG */
    height: 30px; /* Set height of SVG */
	margin-left: 5px;
  }

  .pdf-button:hover {
	background: var(--theme-color);
  }