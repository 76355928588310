//authModal-steps

.authModal {
  &-img {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    &.light-theme {
      filter: invert(1);
      .authModal-logo {
        filter: grayscale(1);
      }
    }
  }
  &-logo {
    width: 240px;
    height: auto;
  }
  &-body {
    min-height: 640px;
    background: var(--casino-win-bg);
  }
  &-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &-header {
    margin-block-end: 65px;
  }

  &-link {
    color: #4797ee;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }

  &-checkbox {
    .authModal-text {
      text-align: left;
    }
  }
  &-btn {
    margin-block-start: 40px;
    &.login-btn {
      margin-block-end: 35px;
    }
  }
  &-disabled-btn {
    filter: brightness(60%);
    pointer-events: none;
  }
  &-text {
    color: #a4a4a4;
    text-align: center;
    font-size: 14px;
    line-height: 140%;
    a {
      color: #448fe0;
      text-decoration: none;
    }
    span {
      color: var(--classic-color);
    }
  }
  .link-style {
    color: #448fe0;
    text-decoration: none;
  }
  &-terms {
    margin-block-end: 36px;
  }
  &-forgot {
    margin-block-end: 30px;
    &-icon {
      width: 58px;
      height: auto;
      display: block;
      margin: 0 auto 17px;
    }
    &-text {
      max-width: 295px;
      width: 100%;
      text-align: center;
      color: var(--classic-color);
      margin: 0 auto;
    }
  }
  &-recovery {
    &-links {
      margin-block-start: 50px;
    }
  }
  &-steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 19px;
    margin-block-end: 35px;
    &-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--input-background);
      border: 1px solid #a4a4a4;
      border-radius: 5px;
      color: #a4a4a4;
      font-size: 20px;
      height: 40px;
      font-weight: 700;
      &:not(.cursor-not-allowed) {
        background: var(--theme-color);
        border-color: transparent;
        color: #fff;
      }
    }
  }
  &-phone {
    display: grid;
    grid-template-columns: 1.2fr 2fr;
    grid-gap: 15px;
  }
  &-recoveryText {
    font-size: 12px;
    color: var(--classic-color);
    margin-block-start: 20px;
    text-align: center;
  }
  &-recoveryBtn {
    margin-block-start: 30px;
  }
  &-already {
    margin: 0 auto;
    display: block;
  }
}

// for country select
.suffix-select {
  .ant-select-arrow {
    inset-inline-start: 11px;
    inset-inline-end: initial;
    width: 20px;
    height: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .ant-select-selector {
    padding-inline-start: 41px !important;
  }
  .ant-select-selection-search-input {
    padding-inline-start: 30px !important;
  }
  &.ant-select-status-error {
    border-color: #ff4d4f;
  }
}




@media screen and (max-width: 800px) {
  .authModal-body {
    min-height: initial;
  }
  .authModal-row {
    grid-template-columns: 1fr 2fr;
  }
  .authModal-logo {
    width: 85%;
  }
}

@media screen and (max-width: 650px) {
  .authModal {
    height: 85%;
    margin-block-start: 30px;
    background: var(--casino-win-bg);
  }
  .authModal .ant-modal-content {
    box-shadow: none;
  }
  .authModal-row {
    grid-template-columns: 1fr;
  }
  .authModal-img {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .authModal-header {
    margin-block-end: 40px;
  }
  .authModal-btn {
    margin: 30px 0;
  }
  .authModal-forgot-icon {
    width: 40px;
  }
  .authModal-steps {
    margin-block-end: 25px;
  }
}

@media screen and (max-width: 450px) {
  .authModal-steps-btn {
    font-size: 17px;
  }
}

@media screen and (max-width: 400px) {
  .authModal-recoveryText {
    text-align: left;
  }
  .authModal-steps {
    grid-gap: 15px;
  }
  .authModal-phone {
    grid-gap: 10px;
  }
}

@media screen and (max-width: 350px) {
  .authModal .ant-picker-suffix {
    margin-inline-end: 10px;
    width: 15px;
  }

  .suffix-select {
    .ant-select-arrow {
      inset-inline-start: 11px;
      inset-inline-end: initial;
      width: 15px;
      height: 15px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .ant-select-selector {
      padding-inline-start: 36px !important;
    }
    .ant-select-selection-search-input {
      padding-inline-start: 25px !important;
    }
  }
}
