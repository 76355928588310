.legal {
	&-block {
		background: var(--casino-win-bg);
		padding: 30px;
    color: var(--classic-color);
		box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
		border-radius: 5px;
		font-size: 16px;
		button, a {
			color: #799fc7;
			text-decoration: none;
			text-transform: uppercase;
			transition: .15s;
			text-align: left;
			&:hover {
				text-decoration: underline;
				color: #fff;
			}
		}
	}
	&-links {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 40px;
		&-item {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-gap: 4px;
			&:not(:last-child) {
				margin-block-end: 6px;
			}
		}
	}
	&-divider {
		background: rgba(255, 255, 255, 0.1);
		width: 100%;
		height: 1px;
		margin: 50px 0;
	}
	&-title {
		font-size: 20px;
		color: var(--classic-color) !important;
		margin-block-start: 40px;
		margin-block-end: 10px;
		font-weight: bold;
		text-transform: uppercase;
	}
	&__title {
		background: #0D1013;
		padding: 30px;
		font-size: 24px;
		color: #fff;
		font-weight: 700;
	}
	&__content {
		padding: 30px;
		color: rgba(255, 255, 255, 0.60);
		font-size: 16px;
		line-height: 130%;
	}
	h2 {
		font-size: 20px;
		text-transform: uppercase;
		color: #fff;
		font-weight: 700;
		margin-bottom: 32px;
	}
	p {
		margin-bottom: 0;
		color: rgba(255, 255, 255, 0.80);
		span {
			font-size: 18px;
			color: #fff;
			font-weight: 600;
		}
		&.mb {
			margin-bottom: 24px;
		}
		&.mt {
			margin-top: 32px;
		}
		&.white-color {
			color: #fff;
			text-transform: uppercase;
			font-weight: 600;
		}
	}
	&__table {
		width: 100%;
		border-collapse: collapse;
		border-radius: 8px;
		border: 1px solid rgba(255, 255, 255, 0.24);
		overflow: hidden;
		&-container {
			border: 1px solid rgba(255, 255, 255, 0.24);
			border-radius: 8px;
			margin: 32px 0;
			overflow-x: auto;
		}
		&-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 32px;
			align-items: flex-start;
			.legal__table-container {
				margin: 0;
				margin: 32px 0;
			}
		}
		th, td {
			padding: 8px;
			text-align: left;
			border: 1px solid rgba(255, 255, 255, 0.24);;
		}
		th {
			padding: 24px;
			background: rgba(255, 255, 255, 0.04);
			font-size: 18px;
			color: #fff;
			font-weight: 600;
		}
		td {
			padding: 16px 24px;
			&.td-min-width {
				min-width: 230px;
			}
		}
	}
}

@media screen and (max-width: 1120px) {
	.legal__table-grid {
		grid-template-columns: 1fr;
		grid-gap: 0;
		.legal__table-container:first-child {
			margin-bottom: 0;
		}
 	}
}

@media screen and (max-width: 700px) {
	.legal-block {
		font-size: 14px;
		padding: 20px;
	}
}

@media screen and (max-width: 600px) {
	.legal__content {
		padding: 20px 15px;
	}
	.legal__title {
		padding: 20px 15px;
	}
	.legal__table th {
		font-size: 16px;
	}
	.legal__table td {
		font-size: 14px;
	}
}

@media screen and (max-width: 520px) {
	.legal-links {
		grid-template-columns: 1fr;
		grid-gap: 0;
	}
	.legal-title {
		font-size: 16px;
		margin-block-end: 20px;
		font-weight: bold;
	}
	.legal-divider {
		margin: 35px 0;
	}
}
