.casinoInfoModal {
	&-row {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 30px;
	}
	&-img {
		margin-block-end: 40px;
		img {
			width: 100%;
			height: auto;
		}
	}
	&-demo {
		margin-block-start: 15px;
	}
	&-title {
		color: var(--classic-color);
		font-size: 20px;
		margin-block-end: 30px;
	}
	&-text {
		margin: 0;
		color: var(--classic-color);
		font-size: 14px;
		span {
			color: var(--theme-color);
		}
		&:not(:last-child) {
			margin-block-end: 10px;
		}
	}
}

@media screen and (max-width: 530px) {
	.casinoInfoModal-row {
		grid-template-columns: 1fr;
	}
	.casinoInfoModal-img {
		margin-block-end: 20px;
	}
}

