.cabinet-subNav {
	padding: 20px 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&-links {
		display: flex;
		align-items: center;
	}
	&-link {
		font-size: 16px;
		color: #909298;
		cursor: pointer;
		&.active {
			color: var(--classic-color);
		}
		&:not(:last-child) {
			margin-inline-end: 30px;
		}
	}
	&-info {
		&-content {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		img {
			margin-inline-end: 10px;
		}
	}
	&-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.ant-select {
		width: 100%;
	}
	&-payment {
		.inputGroup {
			width: 250px;
			margin-inline-start: 15px;
			flex-shrink: 0;
			flex-grow: 0;
			.ant-select {
				width: 100%;
			}
		}
	}
	&-history {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		&-selects {
			display: flex;
			align-items: flex-end;
			.inputGroup {
				width: 200px;
				flex-shrink: 0;
				flex-grow: 0;
				&:not(:last-child) {
					margin-inline-end: 20px;
				}
			}
		}
	}
	&-promo {
		align-items: flex-end;
	}
}

@media screen and (max-width: 1320px) {
	.cabinet-subNav {
		padding-inline-start: 20px;
		padding-inline-end: 20px;
	}
	.cabinet-subNav-payment .inputGroup {
		width: 180px;
	}
}

@media screen and (max-width: 920px) {
	.cabinet-subNav-history {
		flex-direction: column;
		align-items: flex-start;
		.d-flex {
			margin-block-start: 20px;
			justify-content: flex-start;
			width: 100%;
			.cabinet-btn {
				width: auto;
			}
		}
	}
	.cabinet-subNav-history-selects {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 15px;
		width: 100%;
		.inputGroup {
			width: 100%;
		}
	}
	.cabinet-subNav-history-selects .inputGroup:not(:last-child) {
		margin-inline-end: 0;
	}
}

@media screen and (max-width: 880px) {
	.cabinet-subNav-promo {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;
	}
}

@media screen and (max-width: 770px) {
	.cabinet-subNav-components {
		position: relative;
		justify-content: flex-end;
	}
	.cabinet-subNav-info {
		position: absolute;
		top: -30px;
		inset-inline-end: 0;
		img {
			width: 20px;
			height: 20px;
		}
	}
	.cabinet-subNav-links {
		width: 100%;
		flex-wrap: wrap;
	}
	.cabinet-subNav-link {
		margin: 4px 0;
	}
	.cabinet-subNav-link:not(:last-child) {
		margin-inline-end: 20px;
	}
}

@media screen and (max-width: 590px) {
	.cabinet-subNav-history {
		&-selects {
			grid-template-columns: repeat(1, 1fr);
		}
		.d-flex {
			.cabinet-btn {
				width: 100%;
			}
		}
	}
}



@media screen and (max-width: 440px) {
	.cabinet-subNav-payment {
		display: block;
	}
	.cabinet-subNav .inputGroup {
		margin-inline-start: 0;
		margin-block-start: 6px;
		width: 100%;
	}
	.cabinet-subNav-link {
		font-size: 14px;
	}
}