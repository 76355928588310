.landing {

}

.landHeader {
	position: relative;
	z-index: 3;
	padding: 0 20px;
	height: 90px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%), #1D282D;
	filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.24));
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	@media screen and (max-width: 1170px) {
		&.opened {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			grid-template-columns: 1fr auto;
			.landHeader__products {
				display: none;
			}
		}
	}
	&__products {
		display: flex;
		align-items: center;
		&-item {
			width: 116px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 6px 24px;
			border-radius: 12px;
			cursor: pointer;
			transition: .15s;
			position: relative;
			img {
				width: 100px;
				height: auto;
				transition: all .15s;
			}
			&:not(:last-child) {
				margin-right: 8px;
			}
		}
		&-line {
			width: 24px;
			height: 1px;
			opacity: 0;
			transition: .15s;
			&.casino {
				background: #3CC;
			}
			&.sports {
				background: #FD5353;
			}
			&.poker {
				background: #53B1FD;
			}
		}
	}
	&__logo {
		position: relative;
		height: 100%;
		width: 300px;
		img {
			position: absolute;
			width: 100%;
			left: 0;
			height: auto;
			right: 0;
			margin: 0 auto;
			top: -20px;
		}
	}
	&__actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		&-item {
			text-decoration: none;
			&.mr {
				margin-right: 20px;
			}
			p {
				font-size: 18px;
				color: #fff;
				margin-left: 15px;
				margin-bottom: 0;
			}
		}
		&-rounded, #navbar-lang-container, #navbar-langM-container {
			width: 44px;
			height: 44px;
			border-radius: 50%;
			border: 1px solid rgba(255, 255, 255, 0.08);
			background: #223239;
			box-shadow: 0px 4.4px 4.4px 0px rgba(0, 0, 0, 0.16), 0px 6.6px 8.8px 0px rgba(255, 255, 255, 0.06) inset;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .15s;
			&:hover {
				border-color: rgba(255, 255, 255, 0.16);
				background: #223239;
				box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16), 0px 6px 32px 0px rgba(255, 255, 255, 0.16) inset;
			}

			svg {
				width: 65%;
				height: auto;
			}
		}
		&-btns {
			display: flex;
			align-items: center;
			margin-left: 24px;
			.header-btn {
				text-transform: capitalize;
				font-size: 16px;
				&:first-child {
					margin-right: 16px;
				}
			}
		}
		&-login {
			border: 1px solid rgba(255, 255, 255, 0.24);
			text-align: center;
			min-height: 42px;
			border-radius: 100px;
			padding: 0 30px;
			font-size: 16px;
			font-weight: 700;
			transition: .2s;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				border-color: rgba(255, 255, 255, 0.72);
				background: rgba(255, 255, 255, 0.04);
			}
		}
	}
	.navbar-lang {
		transform: scale(0.6);
	}
	&__mobileBtn {
		display: flex;
		align-items: center;
		padding: 10px;
		svg {
			width: 22px;
			height: auto;
		}
		@media screen and (min-width: 1171px) {
			display: none;
		}
	}
	&__mobileMenu {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		height: 100%;
		background: #162C36;
		width: 100%;
		z-index: 2;
		padding: 120px 20px 0;
		overflow-y: auto;
		grid-template-rows: 1fr auto;
		grid-gap: 20px;
		&.opened {
			@media screen and (max-width: 1170px) {
				display: grid;
			}
		}
		&-links {

		}
		.landHeader__products-item {
			width: 100%;
			padding: 24px 24px;
			background: rgba(255, 255, 255, 0.04);
			border-radius: 12px;
			&:not(:last-child) {
				margin-bottom: 12px;
			}
			img {
				width: 135px;
			}
		}
		&-bottom {
			margin-bottom: 32px;
		}
		&-actions {
			margin-bottom: 32px;
			&.landHeader__actions {
				display: flex;
				justify-content: flex-start;
			}
		}
		&-btns {
			.landHeader__actions-login {
				width: 100%;
				margin-top: 20px;
			}
			.header-btn {
				height: 54px;
				span {
					font-size: 18px;
				}
			}
		}
	}
	.navbar-profile {
		display: flex;
	}
}

@media (hover: hover) and (min-width: 1171px) {
	.landHeader__products-item:hover {
		background: rgba(255, 255, 255, 0.08);
		.landHeader__products-line {
			opacity: 1;
		}
		img {
			margin-bottom: 8px;
		}
	}
}

@media screen and (max-width: 1170px) {
	.landHeader {
		height: 70px;
	}
	.landHeader__actions {
		display: none;
	}
	.landHeader {
		grid-template-columns: auto 1fr auto;
	}
	.landHeader__logo {
		order: -1;
	}
	.landHeader__products {
		justify-content: flex-end;
	}
	.landHeader__products-item {
		padding: 12px 20px;
	}
	.landHeader__products-item img {
		width: 85px;
	}
	.landHeader__products-line {
		display: none;
	}
	.landHeader__logo {
		width: 270px;
	}
	.landHeader__logo img {
		top: -34px;
		left: -85px;
	}
	.landHeader__actions-item {
		display: flex;
		align-items: center;
		&.mr {
			margin-right: 40px;
		}
	}
	.landHeader__mobileMenu {
		.navbar-lang-option, .ant-select-selection-item {
			width: 24px !important;
			height: 24px !important;
		}
		.ant-select-selector {
			align-items: center;
		}
	}
}

@media screen and (max-width: 730px) {
	.landHeader__products {
		display: none;
	}
	.landHeader {
		grid-template-columns: 1fr auto
	}
}

@media screen and (max-width: 500px) {
	.landHeader__mobileMenu-actions.landHeader__actions {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 15px;
	}
	.landHeader__actions-item.mr {
		margin-right: 0;
	}
}

@media screen and (max-width: 435px) {
	.landHeader__actions-item p {
		font-size: 16px;
		margin-left: 12px;
	}
}

@media screen and (max-width: 400px) {
	.landHeader__logo {
		width: 225px;
	}
	.landHeader__logo img {
		top: -24px;
	}
}

@media screen and (max-width: 380px) {
	.landHeader__actions-item p {
		display: none;
	}
	.landHeader__mobileMenu-actions.landHeader__actions {
		width: max-content;
		margin-left: auto;
		margin-right: auto;
	}
}
