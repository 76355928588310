[dir="rtl"] {
	--gslider-title-background: linear-gradient(-90deg, #0A101A 0%, rgba(0, 0, 0, 0.00) 52.18%);
	.gamesSlider-btns-next img {
		transform: none;
	}
	.gamesSlider-btns-prev img {
		transform: scale(-1, 1);
	}
	#launcher, #webWidget {
		left: 0 !important;
		right: initial !important;
	}
	.sidebarM-main {
		transform: translate3d(100%, 0, 0);
		&.active {
			transform: translate3d(0, 0, 0);
		} 
	}
	.sidebarM-link-chevron {
		transform: scale(-1, 1);
	}
}