.landProducts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 2100px;
  margin: 10px auto 20px;
  &__content {
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;
    align-items: center;
    height: 100%;
    p {
      margin-bottom: 0;
    }
  }
  &__item {
    min-height: 370px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &-text {
      color: #fff;
      text-align: center;
      font-size: 25px;
      line-height: 120%;
      font-weight: 800;
      text-transform: uppercase;
      span {
        font-size: 48px;
        font-weight: 700;
        text-align: center;
        line-height: 130%;
        display: block;
      }
    }
    &.casino {
      .landProducts__item-text span {
        color: #3cc;
      }
    }
    &.sport {
      .landProducts__item-text span {
        color: #fd5353;
      }
    }
    &.poker {
      .landProducts__item-text span {
        color: #53b1fd;
      }
    }
  }
  .theme-btn {
    text-transform: capitalize;
    margin: 30px auto 0;
    font-size: 24px;
    font-weight: 400;
    min-height: 62px;
    min-width: 200px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 18px;
    &:before {
      border-radius: 18px;
    }
  }
}

.casino-btn-style.theme-btn {
  box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 24px 0px rgba(51, 204, 158, 0.48);
  &:hover {
    background: #3fdbdb;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 64px 0px rgba(51, 204, 158, 0.72);
  }
}

.poker-btn-style.theme-btn {
  border-color: #2d80fe;
  background: #3988ff;
  box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 24px 0px rgba(45, 128, 254, 0.48);
  &:hover {
    border-color: #458fff;
    background: #589aff;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 64px 0px rgba(45, 128, 254, 0.72);
  }
}

.sport-btn-style.theme-btn {
  border-color: #f93540;
  background: #ff3d47;
  box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 24px 0px rgba(249, 53, 64, 0.48);
  &:hover {
    background: #ff515b;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 64px 0px rgba(249, 53, 64, 0.72);
  }
}

@media screen and (max-width: 1420px) {
  .landProducts__item-text {
    font-size: 22px;
  }
  .landProducts__item-text span {
    font-size: 44px;
  }
}

@media screen and (max-width: 1300px) {
  .landProducts .theme-btn {
    min-height: 72px;
    font-size: 20px;
  }
}

@media screen and (max-width: 1170px) {
  .landProducts {
    grid-template-columns: 1fr;
  }
  .landProducts__item {
    padding: 60px 20px;
  }
  .landProducts__item-text {
    line-height: 140%;
  }
}

@media screen and (max-width: 670px) {
  .landProducts .theme-btn {
    min-height: 70px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 400px) {
  .landProducts__item-text span {
    font-size: 36px;
  }
  .landProducts__item-text {
    font-size: 20px;
  }
}

@media screen and (max-width: 375px) {
  .landProducts .theme-btn {
    font-size: 18px;
  }
}
