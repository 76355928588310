.landBanner {
  .page-loading {
    margin-top: 110px;
    margin-bottom: 20px;
  }
  // .swiper-slide {
  //   height: auto;
  // }
  &__slide {
    margin: 0 auto;
    position: relative;
    max-height: 900px;
    display: flex;
    align-items: center;
    height: 100%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.64) 100%);
    }
    &-img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    &-float {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 780px;
      height: auto;
      z-index: 2;
    }
    &-btn {
      display: flex;
    }
    &-content {
      max-width: 800px;
      svg {
        margin-bottom: 15px;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 170px * 2);
      margin: 0 auto;
      position: relative;
      z-index: 3;
      padding: 70px 0;
      min-height: calc(100vh - 90px - 50px);
      &.pd-b {
        padding-bottom: 70px !important;
      }
    }
    &-logo {
      position: relative;
      left: -43px;
    }
    &-text {
      font-size: 24px;
      font-weight: 600;
      color: #fff;
    }
    &-bottom {
      color: #fff;
      font-size: 14px;
      margin-top: 32px;
      margin-bottom: 0;
      &:empty {
        display: none;
      }
    }
    &-block {
      font-family: "Open Sans";
      font-weight: 800;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 24px;
      align-items: center;
      p {
        margin-bottom: 0;
      }
      p:first-child {
        color: #ffbf1e;
        font-size: 120px;
        line-height: 80%;
      }
      p:last-child {
        color: #fff;
        font-size: 40px;
        line-height: 100%;
        width: 100%;
      }
    }
  }
  .theme-btn {
    min-height: 70px;
    font-size: 24px;
    margin-top: 32px;
    text-transform: initial;
    border-radius: 18px;
    padding: 0 24px;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 24px 0px rgba(51, 204, 158, 0.48);
    font-weight: 400;
    &:before {
      border-radius: 18px;
    }
    &:hover {
      background: #3fdbdb;
      box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 64px 0px rgba(51, 204, 158, 0.72);
    }
  }
  .swiper {
    height: 100%;
  }
  .swiper-pagination {
    background: transparent;
  }
  .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.35);
    opacity: 1;
    border-radius: 10px;
    transition: all 0.15s;
    box-shadow: none;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #0b3d3d;
    width: 70px;
  }
  .gradient-text {
    background-color: #fff;
    background-image: linear-gradient(0deg, #ffbf1e 0%, #ffda72 100%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
}

@media screen and (max-width: 1758px) {
  .landBanner__slide-float {
    width: 650px;
  }
}

@media screen and (max-width: 1616px) {
  .landBanner__slide-container {
    width: calc(100% - 80px);
  }
}

@media screen and (max-width: 1540px) {
  .landBanner__slide-block p:last-child {
    font-size: 38px;
  }
  .landBanner__slide-block p:first-child {
    font-size: 110px;
  }
  .landBanner__slide-content svg {
    width: 220px;
    height: auto;
  }
}

@media screen and (max-width: 1300px) {
  .landBanner__slide-block {
    display: flex;
    justify-content: center;
    p:first-child {
      font-size: 96px;
    }
    p:last-child {
      font-size: 36px;
      width: auto;
    }
  }
  .landBanner__slide-content {
    margin: 0 auto;
    max-width: 780px;
    svg {
      margin: 0 auto 15px;
      display: block;
    }
  }
  .landBanner__slide-container {
    min-height: initial;
    padding-bottom: 0;
  }
  .landBanner__slide-text,
  .landBanner__slide-bottom {
    text-align: center;
  }
  .landBanner__slide-btn {
    justify-content: center;
  }
  .landBanner__slide {
    flex-direction: column;
  }
  .landBanner__slide-float {
    width: 500px;
    position: static;
    margin-top: 30px;
  }
  .landBanner .theme-btn {
    min-height: 72px;
    font-size: 20px;
  }
}

@media screen and (max-width: 991px) {
  .landBanner__slide-block p:last-child {
    font-size: 30px;
  }
  .landBanner__slide {
    max-height: initial;
  }
}

@media screen and (max-width: 730px) {
  .landBanner__slide-block {
    grid-template-columns: 1fr;
    display: grid;
    text-align: center;
  }
}

@media screen and (max-width: 670px) {
  .landBanner__slide-text {
    font-size: 18px;
  }
  .landBanner__slide-block {
    grid-gap: 15px;
    p:first-child {
      font-size: 62px;
    }
    p:last-child {
      font-size: 24px;
    }
  }
  .landBanner .theme-btn {
    min-height: 70px;
    padding: 0 20px;
  }
  .landBanner__slide-float {
    max-width: 350px;
    width: 100%;
  }
  .landBanner__slide-container {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 375px) {
  // .landBanner__slide-block {
  //   p:first-child {
  //     font-size: 60px;
  //   }
  //   p:last-child {
  //     font-size: 18px;
  //   }
  // }
  .landBanner .theme-btn {
    font-size: 18px;
  }
}
