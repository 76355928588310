/* common variables */
body {
	--fill-btn-color: #fff;
	--header-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

}


.dark {
	--theme-color: #3CC;
	--sidebar-link-shadow: 0px 0px 7px rgba(51, 204, 204, 0.35);
	--classic-color: #fff;
	--button-outline-shadow-hover: 0px 0px 12px rgba(51, 204, 204, 0.65), inset 0px 0px 16px #3CC, inset 0px -6px 5px rgba(0, 0, 0, 0.25);
	--button-filled-shadow-hover: 0px 0px 12px rgba(51, 204, 204, 0.65);
	--header-outline-btn-bg: #131922;
	--header-bg: linear-gradient( 180deg, rgba(255, 255, 255, 0.2) -130.71%, rgba(255, 255, 255, 0) 100% ), #1D282D;
	--header-search-bg: #131922;
	--sidebar-bg: linear-gradient(264deg, rgba(255, 255, 255, 0.20) -944.87%, rgba(255, 255, 255, 0.00) 108.12%), #1D282D;
	--sidebar-block-bg: #131D22;
	--sidebar-link-color: #B7B7B7;
	--gslider-title-background: linear-gradient(90deg, #0A101A 0%, rgba(0, 0, 0, 0.00) 52.18%);
	--footer-bg: #080A0E;
	--footer-bg-shadow: 0px -21px 38px 0px rgba(0, 0, 0, 0.25);
	--casino-win-bg: #1D282D;
	--input-background: #131922;
	--input-border-color: rgba(255, 255, 255, .17);
	--login-input-active-autofill: 0 0 0px 500px #131922 inset;
	--casino-category-img: invert(61%) sepia(100%) saturate(278%) hue-rotate(131deg) brightness(116%) contrast(103%);
	--cabinet-nav-bg: #1D282D;
	--cabinet-form-bg: #131D22;
	--casino-nav-img: invert(61%) sepia(100%) saturate(278%) hue-rotate(131deg) brightness(116%) contrast(103%);
	--navbar-search-border: #1D282D;
	--green-text: #7CF102;
	--table-border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	--banner-bg-slide-m: rgba(0, 0, 0, .7);
	--side-menu-selected: rgba(255, 255, 255, 0.08);
	--footer-text-clr: #67696f;


}

