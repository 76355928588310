html.sport-page {
	--theme-color: #fb444e;
	--sidebar-link-shadow: 0px 0px 7px rgba(251, 68, 78, 0.35);
	--button-filled-shadow-hover: 0px 0px 12px rgba(251, 68, 78, 0.65);
	--casino-nav-img: invert(44%) sepia(97%) saturate(4722%) hue-rotate(337deg) brightness(109%) contrast(97%);
}

.content-px {
	padding-inline-start: 30px;
	padding-inline-end: 30px;
	@media screen and (max-width: 700px) {
		padding-inline-start: 15px;
		padding-inline-end: 15px;
	}
}

.content-py {
	padding-block-start: 50px;
	padding-block-end: 50px;
	@media screen and (max-width: 700px) {
		padding-block-start: 30px;
		padding-block-end: 30px;
	}
}

.page-layout {
	padding: 15px 0 30px;
}

.no-pointer {
	cursor: initial;
}

.on-desktop {
	@media screen and (max-width: 1320px) {
		display: none !important;
	}
}

.on-tablet {
	@media screen and (min-width: 1321px) {
		display: none !important;
	}
}

.on-mobile {
	@media screen and (min-width: 701px) {
		display: none !important;
	}
}

.chevron-left {
	transform: scale(-1, 1);
}

.layout {
	display: grid;
	grid-template-columns: 256px calc(100% - 256px);
	width: 100%;
	margin: 0 auto;
	&-content {
		min-height: 100vh;
		display: grid;
		grid-template-rows: auto 1fr auto;
		// overflow-x: hidden;
		width: 100%;
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		background-color: var(--sidebar-block-bg);

		/* Firefox */

		&::-webkit-scrollbar {
			display: none;
		}
	}
	&-title {
		font-size: 50px;
		color: var(--classic-color);
		margin-block-end: 30px;
		font-weight: 600;
		@media screen and (max-width: 700px) {
			font-size: 40px;
			margin-block-end: 25px;
		}
		@media screen and (max-width: 480px) {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1320px) {
		grid-template-columns: 1fr;
	}
}

.uw-content {
	max-width: 1740px;
	margin: 0 auto;
	width: 100%;
}

.black-image {
	filter: brightness(0);
}

.no-results {
	font-size: 16px;
	color: var(--classic-color);
	margin: 0;
	@media screen and (max-width: 800px) {
		font-size: 14px;
	}
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.t-center {
	text-align: center;
}

.ant-spin {
	color: var(--theme-color);
}
.ant-spin-dot-item {
	background-color: var(--theme-color);
}

#main-content {
	background-color: var(--sidebar-block-bg);
	overflow: hidden;
}

.casino-scroll::-webkit-scrollbar,
.game-body::-webkit-scrollbar {
	width: 5px;
}

.casino-scroll-horizontal::-webkit-scrollbar-thumb:horizontal {
	background: #161616;
	border-radius: 20px;
}

.casino-scroll-horizontal::-webkit-scrollbar {
	height: 4px;
}

.casino-scroll-horizontal::-webkit-scrollbar-track {
	border-radius: 3px;
	background-color: var(--navbar-search-border);
}

.casino-scroll::-webkit-scrollbar-track,
.game-body::-webkit-scrollbar-track {
	border-radius: 3px;
	background-color: #5c5200;
}

.casino-scroll::-webkit-scrollbar-thumb,
.game-body::-webkit-scrollbar-thumb {
	background-color: #fcdf01;
	/* color of the scroll thumb */
	border-radius: 20px;
	/* roundness of the scroll thumb */
}

.iframe-page {
	background: #191e29;
}

.errorPage {
	padding: 0;
	.ant-result-title,
	.ant-result-subtitle {
		color: var(--classic-color);
	}
	.ant-result-icon span {
		color: var(--theme-color) !important;
	}
}

.page-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	&-loader {
		border: 1.5px solid rgba(255, 255, 255, 0.1);
		border-top: 1.5px solid #fff;
		border-radius: 50%;
		width: 70px;
		height: 70px;
		animation: spin 1s linear infinite;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.error-text {
	font-size: 12px;
	color: rgb(236, 78, 78);
}
