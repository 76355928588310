.gamesSlider {
  position: relative;
  padding-block-end: 40px;
  &-title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 11px 26px;
    color: var(--classic-color);
    border-inline-start: 8px solid var(--theme-color);
    background: var(--gslider-title-background);
		&.light-effect {
			filter: drop-shadow(-5px 2px 10px rgba(0, 0, 0, 0.1));
		}
  }
  &-slide {
    .gameCard {
      width: 100%;
      padding: 0 20px;
    }
    .glow {
      max-width: 164px;
    }
    .gameCard-name {
      font-size: 20px;
    }
  }
  &-btns {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    height: 52px;
    display: flex;
    align-items: center;
    &-next,
    &-prev {
      display: flex;
      align-items: center;
    }
		&-prev {
			order: 1;
		}
    &-next {
			order: 2;
      img {
        transform: scale(-1, 1);
      }
    }
  }
  .react-multi-carousel-list {
    position: unset;
  }
}

@media screen and (max-width: 1420px) {
  .gamesSlider-slide .gameCard-name {
    font-size: 16px;
  }
}

@media screen and (max-width: 1320px) {
  .gameCard.is-round .gameCard-img img {
    transform: scale(1);
  }
  .gamesSlider-title {
    font-size: 16px;
    border-inline-start-width: 6px;
    padding: 6px 15px;
  }
  .gamesSlider-btns {
    height: 40px;
  }
 
  .gameCard-num {
    font-size: 13vw;
    height: 14vw;
    top: -5vw;
    width: 7vw;
  }
  .gameCard-name {
    margin-block-start: 2vw;
  }
}

@media screen and (max-width: 800px) {
  .gamesSlider-btns {
    height: 36px;
    button {
      height: 30px;
      width: 30px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .gameCard-num {
    font-size: 18vw;
    top: -8vw;
    width: 11vw;
  }
	.gamesSlider-slide .gameCard {
		padding: 0 10px;
	}
	.gamesSlider-slide .gameCard-name {
		font-size: 14px;
		margin-block-start: 4vw;
	}
}

@media screen and (max-width: 500px) {
  .gameCard-num {
    font-size: 27vw;
    height: 28vw;
    top: -14vw;
    width: 17vw;
    inset-inline-start: -1vw;
  }
	.gamesSlider-slide .gameCard-name {
		font-size: 12px;
	}
}

