.ant-table-thead>tr>th {
	transition: none !important;
}

td.ant-table-cell>p {
	font-size: 14px;
}

.ant-table {
	background: var(--cabinet-nav-bg);
}

.ant-table-row:hover {
  background-color: none;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: none;
}


.ant-table-content {
  background: none !important;
  color: var(--classic-color);
}

.ant-table-content {
	background: transparent;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
	display: none;
}

.ant-table-thead .ant-table-cell {
  background-color: var(--header-search-bg);
  color: var(--classic-color);
  border-bottom: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

td.ant-table-cell {
  padding: 8px 16px !important;
}
td.ant-table-cell > p {
  margin: 0px !important;
}

.ant-table-tbody > tr > td {
  border-bottom: var(--table-border-bottom);
}

.ant-pagination {
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ant-pagination-item-active {
  border-color: var(--theme-color);
}

.ant-pagination-item, .ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--classic-color);
	background: transparent;
}

.ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link, .ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link {
	border-color: var(--theme-color);
}

@media screen and (max-width: 840px) {
	.ant-table-thead .ant-table-cell {
		font-size: 12px;
	}
	td.ant-table-cell>p {
		font-size: 12px;
	}
	.ant-table-container {
		white-space: nowrap;
	}
	.ant-table {
		overflow-x: auto;
	}
}