.main-navbar {
  position: sticky;
  touch-action: none;
  background: var(--header-bg);
  width: 100%;
  height: 70px;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--header-shadow);
  &-responsiveMenu {
    display: none;
  }
  &-right {
    display: flex;
    align-items: center;
  }
  &-btns {
    display: grid;
    grid-template-columns: 164px;
    grid-gap: 20px;
  }
  .divider {
    width: 1px;
    height: 40px;
    margin: 0 20px;
    background: #717171;
  }
}

#navbar-lang-container,
#navbar-langM-container {
  position: relative;
  display: flex;
  align-items: center;
  .ant-select-selector {
    border: 0 !important;
  }
}

.navbar-lang {
  .ant-select-selector {
    padding: 0 !important;
    height: 42px !important;
    .navbar-lang-option {
      margin-inline-end: 0 !important;
      width: 42px;
      height: 42px;
    }
  }
  .ant-select-selection-item {
    width: 42px;
    height: 42px;
    color: transparent !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 42px;
    height: 42px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-option {
    margin-inline-end: 10px;
    border-radius: 50%;
    object-fit: cover;
  }
  &-dropdown {
    // width: max-content !important;
    // width: fit-content;
    width: 220px !important;
    border-radius: 4px;
    inset-inline-start: initial !important;
    inset-inline-end: 0;
    .ant-select-item {
      &:not(:last-child) {
        .ant-select-item-option-content {
          &:before {
            content: "";
            position: absolute;
            bottom: -13px;
            inset-inline-start: 0;
            opacity: 0.1;
            background: var(--classic-color);
            width: 100%;
            height: 1px;
          }
        }
      }
    }
    .rc-virtual-list-scrollbar-thumb {
      background: var(--theme-color) !important;
      border-radius: 5px !important;
    }
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      position: relative;
      overflow: initial;
      padding-inline-end: 34px;
    }
  }
}

.navbar-theme {
  width: 45px;
  height: 45px;
  background: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  transition: 0.2s;
  img {
    width: 46%;
    height: 46%;
  }
  &:hover {
    box-shadow: var(--button-filled-shadow-hover);
    transition: 0.2s;
  }
  &.dark-theme {
    @media screen and (max-width: 1320px) {
      box-shadow: inset 0px -3px 4px rgba(255, 255, 255, 0.15);
      img {
        filter: invert(95%) sepia(40%) saturate(7453%) hue-rotate(348deg) brightness(94%) contrast(110%);
      }
    }
  }
}

.navbar-help {
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  width: 42px;
  height: 42px;
  img {
    width: 100%;
    height: 100%;
  }
}

.navbar-search {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 365px;
  width: 100%;
  &-icon {
    position: absolute;
    top: 0;
    inset-inline-start: 18px;
    bottom: 0;
    margin: auto 0;
    z-index: 1;
  }
  input {
    display: flex;
    background: var(--header-search-bg);
    height: 45px;
    padding-inline-end: 13px;
    padding-inline-start: 50px;
    width: 100%;
    border-radius: 38px;
    border: 1px solid var(--input-border-color);
    cursor: pointer;
    color: var(--classic-color);
    font-size: 14px;
    text-overflow: ellipsis;
    &::placeholder {
      color: #b7b7b7;
    }
  }

  &-res {
    position: absolute;
    width: 650px;
    padding-block-start: 30px;
    padding-block-end: 30px;
    padding-inline-start: 50px;
    padding-inline-end: 30px;
    background: var(--header-search-bg);
    border: 1px solid var(--input-border-color);
    border-radius: 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    inset-inline-start: 0;
    top: calc(100% + 15px);
    text-align: left;
    cursor: initial;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }
  &-title {
    margin-block-end: 30px;
    color: var(--classic-color);
    font-size: 20px;
    text-transform: uppercase;
  }
  &-games {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    &.no-grid {
      display: block;
    }
    .gameCard {
      display: grid;
      grid-template-rows: 90px 1fr;
      align-items: center;
    }
    .gameCard-name {
      margin: 15px 0;
    }
    .gameCard-img {
      height: 100%;
      img {
        object-fit: cover;
      }
    }
  }
  &-game {
    &_img {
      display: flex;
      align-items: center;
    }
    &_name {
      color: var(--classic-color);
      font-size: 10px;
      text-align: center;
      margin-block-start: 6px;
      line-height: 130%;
    }
  }
  &-link {
    display: flex;
    align-items: center;
    color: var(--classic-color);
    font-size: 20px;
    text-decoration: none;
    transition: 0.2s;
    padding: 5px;
    opacity: 0.7;
    img {
      margin-inline-end: 12px;
    }
    &:hover {
      opacity: 1;
      color: var(--classic-color);
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    background: var(--classic-color);
    margin: 34px 0;
  }
  &-left {
    display: none;
    align-items: center;
  }
}

.navbar-logo {
  &-t {
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    svg {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }
}

.navbar-time {
  display: flex;
  align-items: center;
  margin-block-start: 30px;
  img {
    margin-inline-end: 10px;
  }
  p {
    color: var(--sidebar-link-color);
    font-size: 16px;
    font-weight: 500;
    margin-block-end: 0;
  }
}

.navbar-depositBtn {
  margin-inline-end: 20px;
}

.navbar-email {
  border-radius: 39px;
  border: 2px solid var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 39px;
  margin-inline-end: 20px;
}

.navbar-profile {
  display: flex;
  align-items: center;
  // margin-inline-end: 20px;
  width: 42px;
  height: 42px;
  img {
    width: 100%;
    height: 100%;
  }
  &-t {
    // margin-inline-end: 20px;
    display: flex;
    align-items: center;
    &-user {
      margin-inline-end: 16px;
      text-align: right;
    }
    &-name {
      font-size: 14px;
      line-height: 1;
      color: #fff;
      font-weight: 600;
    }
    &-balance {
      font-size: 12px;
      color: #fff;
      margin-block-start: 3px;
      line-height: 1;
      font-weight: 600;
    }
    &-btn {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.navbar-mobileBtn {
  display: none;
  div {
    width: 30px;
    height: 5px;
    background: var(--classic-color);
    border-radius: 41px;
    &:not(:last-child) {
      margin-block-end: 5px;
    }
  }
}

@media screen and (max-width: 1580px) {
  .navbar-theme,
  .navbar-profile,
  .navbar-lang .ant-select-selection-item,
  .navbar-lang .ant-select-selector .navbar-lang-option,
  .navbar-help {
    width: 35px;
    height: 35px;
  }
  .navbar-lang .ant-select-selector {
    height: 35px !important;
  }
  .navbar-email {
    padding: 10px 25px;
  }
  .navbar-time p {
    font-size: 14px;
  }
  .main-navbar-right .divider {
    height: 38px;
    margin: 0 15px;
  }
  .main-navbar-btns {
    grid-gap: 15px;
  }
  .navbar-email,
  .navbar-depositBtn {
    margin-inline-end: 15px;
  }
  .navbar-search {
    max-width: 250px;
  }
}

@media screen and (max-width: 1320px) {
  .main-navbar {
    height: 60px;
    width: 100%;
  }
  .main-navbar-right {
    .divider,
    #navbar-lang-container,
    .navbar-email,
    .landHeader__products-item {
      display: none;
    }
  }
  .main-navbar-btns {
    grid-gap: 10px;
    // margin-inline-end: 10px;
    grid-template-columns: repeat(1, 1fr);
    .theme-btn,
    .landHeader__actions-login {
      padding: 0 10px;
      min-width: 130px;
    }
  }
  .main-navbar-left {
    display: flex;
    align-items: center;
  }
  .main-navbar {
    .divider {
      height: 26px;
      margin: 0 16px;
    }
  }
  .navbar-mobileBtn {
    display: block;
  }
  .navbar-profile {
    display: none;
  }
  .navbar-search-link {
    font-size: 14px;
  }
  .navbar-search-link img {
    width: 20px;
    height: auto;
  }
  .navbar-search-title {
    font-size: 14px;
    margin-block-end: 15px;
  }
  .navbar-help {
    width: 40px;
    height: 40px;
    opacity: 1;
  }
  .navbar-email {
    width: 40px;
    height: 40px;
    margin-inline-end: 0;
    border: 1px solid #717171;
    padding: 0;
    border-radius: 50%;
    img {
      width: 50%;
      height: 50%;
      opacity: 0.4;
    }
  }
}

@media screen and (max-width: 700px) {
  .main-navbar-btns {
    margin-inline-end: 0;
    .theme-btn,
    .landHeader__actions-login {
      // min-width: auto;
      min-width: 90px;
      min-height: 30px;
      span {
        font-size: 11px;
      }
    }
  }
  .navbar-mobileBtn div {
    height: 3px;
    width: 21px;
  }
  .navbar-mobileBtn div:not(:last-child) {
    margin-block-end: 3px;
  }
  .main-navbar .divider {
    height: 17px;
  }
  .main-navbar-left {
    align-items: center;
  }
  .main-navbar {
    height: 50px;
  }
  .main-navbar-right {
    .navbar-theme {
      display: none;
    }
  }
  .main-navbar .divider {
    margin: 0 10px;
  }
  .navbar-profile-t-user {
    margin-inline-end: 10px;
    .navbar-profile-t-name {
      display: none;
    }
  }
  .navbar-profile-t {
    margin-inline-end: 0;
  }
  .navbar-profile-t-balance {
    margin-block-start: 0;
  }
  .navbar-logo-t-header {
    height: 30px;
  }
}

@media screen and (max-width: 400px) {
  .navbar-logo-t-header svg {
    height: 22px;
  }
}

@media screen and (max-width: 380px) {
  .navbar-search input {
    padding-inline-start: 34px;
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
  .navbar-search-icon {
    inset-inline-start: 12px;
  }
}

@media screen and (max-width: 360px) {
  .main-navbar .divider {
    margin: 0 7px;
  }
  .main-navbar-btns {
    grid-gap: 6px;
    .theme-btn,
    .landHeader__actions-login {
      font-size: 10px;
    }
  }
}
